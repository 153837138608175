/*
  This files deals with stores for redux
*/

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/auth";
import feedbackReducer from "./features/feedbacks/feedback";
import usersReducer from "./features/users/users";
import workshopsReducer from "./features/workshops/workshop";

export const store = configureStore({
  reducer: { authReducer, feedbackReducer, usersReducer, workshopsReducer },
});
