import { useEffect } from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  AdminPanelSettingsOutlined,
  GppMaybeOutlined,
  LockOpenOutlined,
  SecurityOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // redux store
  const { users } = useSelector((state) => state.usersReducer);

  useEffect(() => {
    // setting the title
    document.title = "Meri Awaaz | Users";
  }, []);
  const columns = [
    // { field: "user_id", headerName: "ID", flex: 0.5 },
    { field: "user_id", headerName: "User ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1.7,
      cellClassName: "name-column--cell",
      renderCell: (param) => {
        return (
          <div className="alnRow">
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
              }}
              src={param?.row?.profile_image}
            />{" "}
            {param?.row?.first_name} {param?.row?.last_name || ""}
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({
        row: { is_active, is_admin, is_staff, is_superuser },
      }) => {
        let access;

        if (is_superuser) {
          access = "super_user";
        } else if (is_admin) {
          access = "admin";
        } else if (is_staff) {
          access = "staff";
        } else if (is_active) {
          access = "active";
        } else {
          access = "Inactive";
        }

        return (
          <Tooltip title={access?.toUpperCase()}>
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                access === "super_user"
                  ? colors.greenAccent[600]
                  : access === "admin"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {access === "super_user" && <AdminPanelSettingsOutlined />}
              {access === "admin" && <SecurityOutlined />}
              {access === "active" && <LockOpenOutlined />}
              {access === "Inactive" && <GppMaybeOutlined />}
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="Users" subtitle="List of Users" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .alnRow": {
            display: "flex",
            gap: "10px",
            alignItems: "center",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row?.user_id}
        />
      </Box>
    </Box>
  );
};

export default Contacts;
