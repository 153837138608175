/*
    This file deals with states for transformers 
*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingFeedbacks: false,
  feedbacks: [],
  feedbackError: null,
};
const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {
    // define reducer functions to update the state
    loadingFeedbacks(state) {
      state.isFetchingFeedbacks = true;
      state.feedbackError = null;
    },
    feedbackSuccess(state, { payload }) {
      state.isFetchingFeedbacks = false;
      state.feedbacks = payload;
    },
    feedbackFailure(state, { payload }) {
      state.isFetchingFeedbacks = false;
      state.feedbackError = payload;
    },
  },
});

export const { loadingFeedbacks, feedbackSuccess, feedbackFailure } =
  feedbackSlice.actions;

export default feedbackSlice.reducer;
