/*
    This file deals with states for transformers 
*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingUsers: false,
  users: [],
  userError: null,
};
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // define reducer functions to update the state
    loadingUser(state) {
      state.isFetchingUsers = true;
      state.error = null;
    },
    userSuccess(state, { payload }) {
      state.isFetchingUsers = false;
      state.users = payload;
    },
    userFailure(state, { payload }) {
      state.isFetchingUsers = false;
      state.userError = payload;
    },
  },
});

export const { loadingUser, userSuccess, userFailure } = userSlice.actions;

export default userSlice.reducer;
