/*
    This file deals with states for transformers 
*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingWorkshops: false,
  workshops: [],
  workshopError: null,
};
const workshopSlice = createSlice({
  name: "workshop",
  initialState,
  reducers: {
    // define reducer functions to update the state
    loadingWorkshop(state) {
      state.isFetchingWorkshops = true;
      state.workshopError = null;
    },
    workshopSuccess(state, { payload }) {
      state.isFetchingWorkshops = false;
      state.workshops = payload;
    },
    workshopFailure(state, { payload }) {
      state.isFetchingWorkshops = false;
      state.workshopError = payload;
    },
  },
});

export const { loadingWorkshop, workshopSuccess, workshopFailure } =
  workshopSlice.actions;

export default workshopSlice.reducer;
