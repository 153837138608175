import {
  Email,
  Error,
  Lock,
  LoginSharp,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../helpers/auth";
import { loginStart } from "../../redux/features/auth/auth";

const Login = () => {
  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  const {
    isAuthenticated,
    isLoading,
    error: authError,
  } = useSelector((state) => state.authReducer);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({
    email: null,
    password: null,
    bothError: null,
  });
  // show password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  function handleSubmit(e) {
    e.preventDefault();
    // reset error
    setError({ email: null, password: null, bothError: null });

    // check data is filled or not
    if (!formData?.email?.trim() || !formData?.password?.trim()) {
      setError((prev) => ({
        ...prev,
        bothError: "Please provide email or Password",
      }));
      return;
    }

    if (!validateEmail(formData?.email)) {
      // validate email
      setError((prev) => ({
        ...prev,
        email: "You have entered an invalid email",
      }));
      return;
    }

    dispatch(loginStart());

    // login
    let sendData = new FormData();
    sendData.append("email", formData?.email);
    sendData.append("password", formData?.password);

    login(sendData, dispatch);
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <Box className="login-container">
      <Box className="login-subContainer">
        <Box className="upper-box">
          <img className="logo" src={`../../assets/logo-bg.png`} alt="logo" />
          <img
            className="logo-txt"
            alt="logo-text"
            src={`../../assets/text-bg.png`}
            width="100%"
            height="100%"
          />
        </Box>

        {error?.bothError && (
          <p className="error">
            {" "}
            <Error /> {error?.bothError}
          </p>
        )}

        {authError && (
          <p className="error">
            {" "}
            <Error /> {authError}
          </p>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            required
            fullWidth
            margin="normal"
            size="small"
            label="Email"
            value={formData?.email}
            onChange={(event) => {
              setFormData((prev) => ({ ...prev, email: event.target.value }));
            }}
            helperText={error?.email || ""}
            error={error?.email ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />

          {/* password */}
          <TextField
            required
            margin="normal"
            fullWidth
            size="small"
            label="Password"
            value={formData?.password}
            type={showPassword ? "text" : "password"}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                password: event.target.value,
              }));
            }}
            helperText={error?.password || ""}
            error={error?.password ? true : false}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            onClick={handleSubmit}
            className="login-btn"
            variant="contained"
            disabled={isLoading}
          >
            Login {"  "} <LoginSharp />
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
