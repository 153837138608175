import {
  isLoggedIn,
  setAuthTokens,
  clearAuthTokens,
  getAccessToken,
  getRefreshToken,
} from "axios-jwt";
import { loginFailure, loginSuccess } from "../redux/features/auth/auth";
import { axiosInstance } from "../Utils/axiosInterceptor";

// 4. Post email and password and get tokens in return. Call setAuthTokens with the result.
export const login = async (formData, dispatch) => {
  try {
    const response = await axiosInstance.post("admin/login/", formData);

    // save tokens to storage
    setAuthTokens({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });
    dispatch(loginSuccess({ token: response.data }));
  } catch (error) {
    console.log("error =>", error);

    if (error?.response?.data?.status === 401) {
      dispatch(loginFailure("Email or password is incorrect."));
    } else {
      dispatch(loginFailure("Something went wrong! Please try again."));
    }

    return error;
  }
};

// 5. Remove the auth tokens from storage
export const logout = () => clearAuthTokens();

// Check if refresh token exists
if (isLoggedIn()) {
  // assume we are logged in because we have a refresh token
}

// Get access to tokens
const accessToken = getAccessToken();
const refreshToken = getRefreshToken();
