import { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useSelector } from "react-redux";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { workshops } = useSelector((state) => state.workshopsReducer);

  const columns = [
    { field: "workshop_id", headerName: "ID" },
    {
      field: "institute_name",
      headerName: "Institute Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "venue",
      headerName: "Venue",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "number_of_students",
      headerName: "No.of students",
      flex: 1,
    },
    {
      field: "tentative_date",
      headerName: "Tentative Date",
      flex: 1,
      renderCell: ({ value }) => (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          {value?.toLocaleString() || "--"}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    // setting the title
    document.title = "Meri Awaaz | Workshops";
  }, []);

  return (
    <Box m="20px">
      <Header title="Workshops" subtitle="List of Workshop " />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={workshops}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row?.workshop_id}
        />
      </Box>
    </Box>
  );
};

export default Team;
