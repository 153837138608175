import { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSelector } from "react-redux";

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { feedbacks } = useSelector((state) => state.feedbackReducer);

  const columns = [
    { field: "feedback_id", headerName: "ID" },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
  ];

  useEffect(() => {
    // setting the title
    document.title = "Meri Awaaz | Feedbacks";
  }, []);

  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={feedbacks}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row?.feedback_id}
        />
      </Box>
    </Box>
  );
};

export default Invoices;
