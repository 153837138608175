/*
    This file deals with states for transformers 
*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null, // JWT token received from the server
  isAuthenticated: false, // flag to indicate if the user is authenticated
  isLoading: false, // flag to indicate if the authentication request is in progress
  error: null, // error message if the authentication request fails
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // define reducer functions to update the state
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, { payload }) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.token = payload.token;
    },
    loginFailure(state, { payload }) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      state.error = payload;
    },
    logoutSucces(state) {
      state.isAuthenticated = false;
      state.token = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logoutSucces } =
  authSlice.actions;

export default authSlice.reducer;
