import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from "./scenes/Login";
import NotFound from "./scenes/notFound";
import AuthProtectedRoutes from "./Routes/AuthProtectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "./Utils/axiosInterceptor";
import { getAccessToken, getRefreshToken, isLoggedIn } from "axios-jwt";
import { loginSuccess } from "./redux/features/auth/auth";
import {
  loadingUser,
  userFailure,
  userSuccess,
} from "./redux/features/users/users";
import {
  loadingWorkshop,
  workshopFailure,
  workshopSuccess,
} from "./redux/features/workshops/workshop";
import {
  feedbackFailure,
  feedbackSuccess,
  loadingFeedbacks,
} from "./redux/features/feedbacks/feedback";

function App() {
  const [theme, colorMode] = useMode();
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  // get users list
  async function getUsers() {
    dispatch(loadingUser());
    try {
      let { status, data } = await axiosInstance.get("admin/user/list/");

      if (status === 200) {
        dispatch(userSuccess(data.reverse()));
      }
    } catch (err) {
      dispatch(userFailure("something went wrong!"));
    }
  }

  async function getWorkshop() {
    dispatch(loadingWorkshop());
    try {
      let { status, data } = await axiosInstance.get("admin/workshop/list/");

      if (status === 200) {
        dispatch(workshopSuccess(data.reverse()));
      }
    } catch (err) {
      dispatch(workshopFailure("something went wrong!"));
    }
  }

  async function getFeedbacks() {
    dispatch(loadingFeedbacks());
    try {
      let { status, data } = await axiosInstance.get("admin/feedback/list/");

      if (status === 200) {
        dispatch(feedbackSuccess(data.reverse()));
      }
    } catch (err) {
      dispatch(feedbackFailure("something went wrong!"));
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      // Get access to tokens
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      let payload = {
        token: { accessToken: accessToken, refreshToken: refreshToken },
      };
      dispatch(loginSuccess(payload));
    }
  }, [isLoggedIn()]);

  useEffect(() => {
    if (!isAuthenticated) return;

    // get User data
    getUsers();

    // get workshop List
    getWorkshop();

    // get feedbacks
    getFeedbacks();
  }, [isAuthenticated]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AuthProtectedRoutes />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/workshops" element={<Team />} />
            <Route path="/users" element={<Contacts />} />
            <Route path="/feedbacks" element={<Invoices />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
