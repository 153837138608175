import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../scenes/global/Sidebar";
import Topbar from "../scenes/global/Topbar";

const AuthProtectedRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const [isSidebar, setIsSidebar] = useState(true);

  return isAuthenticated ? (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Outlet />
      </main>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthProtectedRoutes;
